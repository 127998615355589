import { z } from "zod"

import { USER_ROLES } from "@kiosk/types/user"

import { passwordConfirmationRefinement } from "@kiosk/shared/utils/helpers"

export const userSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phone: z.string().optional().nullable(),
  avatarUrl: z.string().optional().nullable(),
  jobTitle: z.string().optional().nullable(),
  role: z.nativeEnum(USER_ROLES),
  locale: z.enum(["fr", "en"]),
})
export type UserBody = z.infer<typeof userSchema>

export const userUpdateSchema = userSchema.partial()
export type UserUpdateBody = z.infer<typeof userUpdateSchema>

const passwordSchema = z
  .string()
  .min(8, {
    // Check if password has at least 8 characters
    // TODO: password-checker library
    message: "Password must be at least 8 characters long",
  })
  .superRefine((value, ctx) => {
    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(value)) {
      ctx.addIssue({
        code: "custom",
        message: "Password must contain at least one uppercase letter",
        path: ["password"],
      })
    }

    // Check if password contains at least one number
    if (!/\d/.test(value)) {
      ctx.addIssue({
        code: "custom",
        message: "Password must contain at least one number",
        path: ["password"],
      })
    }
  })

// TODO: need for a password strength checker?
export const setupUserAccountSchema = passwordConfirmationRefinement(
  z.object({
    firstName: z.string(),
    lastName: z.string(),
    password: passwordSchema,
    confirmPassword: z.string(),
  }),
)

export type UserSetupAccountBody = z.infer<typeof setupUserAccountSchema>

export const resetPasswordSchema = passwordConfirmationRefinement(
  z.object({
    password: passwordSchema,
    confirmPassword: z.string(),
  }),
)

export type updatePasswordBody = z.infer<typeof resetPasswordSchema>

export const createCompanyContributorSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  jobTitle: z.string().nullish().optional(),
})
export type CreateCompanyContributorBody = z.infer<
  typeof createCompanyContributorSchema
>
