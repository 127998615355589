import {
  Button,
  Divider,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { User, UserRole } from "@kiosk/types/prisma-client"
import { USER_ROLES } from "@kiosk/types/user"

import { Locale } from "@kiosk/i18n"

import { UserBody, userSchema } from "@kiosk/shared/schemas/users"

import { useUpdateUserMutation } from "@kiosk/front/api/users/updateUser"
import { PictureUploader } from "@kiosk/front/components/PictureUploader"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { roles } from "@kiosk/front/utils/constants"
import { getInitials } from "@kiosk/front/utils/helpers"

type Props = {
  close: () => void
  opened: boolean
  selectedUserId: string
  selectedUserData: User
}

export const UpdateUser = ({
  close,
  opened,
  selectedUserId,
  selectedUserData,
}: Props) => {
  const { t } = useTranslation(["common"])
  const currentUser = useUser()

  const { reset, getInputProps, isValid, onSubmit, setValues } =
    useForm<UserBody>({
      initialValues: {
        email: selectedUserData.email,
        firstName: selectedUserData.firstName,
        lastName: selectedUserData.lastName,
        avatarUrl: selectedUserData.avatarUrl,
        jobTitle: selectedUserData.jobTitle,
        locale: selectedUserData.locale as Locale,
        role: selectedUserData.role,
      },
      validate: zodResolver(userSchema),
    })

  useEffect(() => {
    if (selectedUserData) {
      setValues({
        email: selectedUserData.email,
        firstName: selectedUserData.firstName,
        lastName: selectedUserData.lastName,
        avatarUrl: selectedUserData.avatarUrl,
        jobTitle: selectedUserData.jobTitle,
        role: selectedUserData.role,
      })
    }
  }, [selectedUserData, setValues])

  const handleCancel = () => {
    reset()
    close()
  }

  const { mutate: updateAccount, isPending } =
    useUpdateUserMutation(selectedUserId)

  const handleUpdateUser = onSubmit(async (data, event) => {
    event?.preventDefault()
    updateAccount({ userId: selectedUserId, data })
    handleCancel()
  })

  return (
    <Drawer.Root position="right" size="lg" opened={opened} onClose={close}>
      <Drawer.Overlay />
      <Drawer.Content>
        <Stack h="100%" gap={32}>
          <Drawer.Header
            p={24}
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            <Drawer.Title fz="lg" fw={600} c="black">
              {t("settings:users.updateUser")}
            </Drawer.Title>
            <Drawer.CloseButton />
          </Drawer.Header>

          <Stack px={24}>
            <Text fw={500} c="black">
              {t("settings:account.profileImage")}
            </Text>
            <Group gap={24}>
              <PictureUploader
                destination="user-avatars"
                placeholder={getInitials(
                  selectedUserData.firstName,
                  selectedUserData.lastName,
                )}
                {...getInputProps("avatarUrl")}
              />
            </Group>
            <Divider />
          </Stack>

          <Stack h="100%" justify="space-between">
            <Drawer.Body px={24}>
              <form id="update-user" onSubmit={handleUpdateUser}>
                <Stack>
                  <TextInput
                    label={t("fields.firstNameLabel")}
                    placeholder={t("fields.firstNamePlaceholder")}
                    {...getInputProps("firstName")}
                  />
                  <TextInput
                    label={t("fields.lastNameLabel")}
                    placeholder={t("fields.lastNamePlaceholder")}
                    {...getInputProps("lastName")}
                  />
                  <TextInput
                    disabled
                    label={t("fields.emailLabel")}
                    placeholder={t("fields.emailPlaceholder")}
                    {...getInputProps("email")}
                  />
                  <TextInput
                    label={t("fields.jobTitleLabel")}
                    placeholder={t("fields.jobTitlePlaceholder")}
                    {...getInputProps("jobTitle")}
                  />
                  <Select
                    disabled={currentUser.role !== USER_ROLES.KIOSK_ADMIN}
                    data={Object.entries(roles).map(([key, value]) => ({
                      value: key,
                      label: value as UserRole,
                    }))}
                    {...getInputProps("role")}
                  />
                </Stack>
              </form>
            </Drawer.Body>
            <Group
              style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
              gap={10}
              p={24}
              justify="flex-end"
            >
              <Button variant="outline" color="gray" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
              <Button
                form="update-user"
                type="submit"
                loading={isPending}
                disabled={!isValid() || isPending}
              >
                {t("settings:users.updateUser")}
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Drawer.Content>
    </Drawer.Root>
  )
}
